/* styles.css */
body {
  margin: 0;
  padding: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #202020;
  color: #fff;
  font-family: Consolas, monospace;
}

.header {
  font-size: 32px;
  margin-bottom: 20px;
  border-right: 2px solid #fff;
  animation: typing 4s steps(40), blink 1s infinite step-end;
  margin: 10px; /* Dodajemy margines 10px */
}

.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 10px; /* Dodajemy margines 10px */
}

.subheader {
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
  word-break: break-word;
  margin: 5px; /* Dodajemy margines 10px */
}

.text {
  font-size: 18px;
  margin: 10px; /* Dodajemy margines 10px */
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  white-space: pre-wrap;
  margin: 0 5px; /* Dodajemy margines po bokach */
}

.prompt {
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
